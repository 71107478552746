import Glide from '@glidejs/glide'

window.glide = new Glide('.slider-container', {
    type: 'carousel',
    perView: 7,
    breakpoints: {
        1920: {
            perView: 5,
        },
        1280: {
            perView: 3,
        },
    },
    gap: 10,
    focusAt: 'center',
    autoplay: 4000,
    hoverpause: true,
    
}).mount();